
import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/system/Box';
import { usePallet } from '../contexts/PalletContext';
import styled from "styled-components";
import useStyles from './layout/Styles';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';

export interface IComboItem {
    value: string,
    label: string
}
interface IProps {
    idCampo: string
    name: string,
    label?: string,
    placeholder?: string,
    helperText?: React.ReactNode,
    value?: string,
    focused?: boolean,
    className?: string,
    inputValue?: string,
    style?: React.CSSProperties,
    menuStyle?: React.CSSProperties,
    itens?: Array<string>,
    startAdornment?: React.ReactNode,
    clear?: boolean,
    onKeyUp?: (e: React.KeyboardEvent) => void,
    onKeyDown?: (e: React.KeyboardEvent) => void,
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
}

export default function ComboBoxBuscaKeyUp(props: IProps) {

    const classes = useStyles();
    const { pallet } = usePallet();
    const [value, setValue] = React.useState<string | null>(props.value || null);
    const [inputValue, setInputValue] = React.useState('');



    return (

            <div>

                        <TextField
                            fullWidth
                            id={props.idCampo}
                            label={props.label ? props.label : null}
                            placeholder={props.placeholder}
                            variant='outlined'
                            onKeyUp={props.onKeyUp}
                            onKeyDown={props.onKeyDown}
                            onChange={props.onChange}
                            InputProps={{
                                classes: {
                                    notchedOutline: classes.notchedOutline
                                },
                                style: {
                                    fontFamily: `${pallet.general.fontFamily}`,
                                    fontSize: `${pallet.general.size14}`,
                                    fontWeight: pallet.general.weight_100,
                                    background: `${pallet.backgroundColorSecondary}`
                                },
                   
                                startAdornment: (
                                    <InputAdornment position="start">
                                        {props.startAdornment ? props.startAdornment : <SearchIcon />}
                                    </InputAdornment>
                                )
                            }}
                        />

            </div>

    );
}